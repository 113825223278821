<template>
  <v-sheet light class="pa-3">
    <v-row>
      <v-col>
        <v-data-iterator
          :items="produits"
          :sort-desc="search.sortDesc"
          sort-by="title"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:header>
            <v-toolbar dark color="blue darken-3" class="mb-1">
              <v-text-field
                v-model="search.title"
                label="Recherche par nom..."
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-select
                v-model="search.categorie"
                :items="categories"
                label="Catégorie"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn-toggle v-model="search.sortDesc" mandatory>
                <v-btn large depressed color="blue" :value="false">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn large depressed color="blue" :value="true">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-toolbar>
          </template>

          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.title"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-card>
                  <v-img :src="item.img" max-height="400px" contain></v-img>
                  <!-- <v-card-title v-text="item.title"></v-card-title> -->
                  <v-list two-lines>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-subtitle v-text="categorieFormatted(item.categories)"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-avatar>
                        <v-icon class="grey lighten-1" dark>mdi-information-variant</v-icon>
                      </v-list-item-avatar>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "produits",
  data: () => ({
    options: {
      sortBy: ["categories", "title"],
    },
    search: {
      title: null,
      categorie: null,
      sortDesc: false
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: "Photo",
          value: "img",
          align: "center",
          width: "150",
        },
        {
          text: "Nom",
          value: "title",
          align: "start",
          filter: (value) => {
            if (!this.search.title) return true;
            return (
              value.toLowerCase().indexOf(this.search.title.toLowerCase()) != -1
            );
          },
        },
        {
          text: "Catégories",
          value: "categories",
          align: "start",
          filter: (value) => {
            if (!this.search.categorie) return true;
            return (
              value
                .toLowerCase()
                .indexOf(this.search.categorie.toLowerCase()) != -1
            );
          },
        },
      ];
    },
    produits() {
      return this.$store.state.produits
        .filter((item) => {
          if (!this.search.title) return true;
          else
            return (
              item.title
                .toLowerCase()
                .indexOf(this.search.title.toLowerCase()) != -1
            );
        })
        .filter((item) => {
          if (!this.search.categorie) return true;
          else
            return (
              item.categories
                .toLowerCase()
                .indexOf(this.search.categorie.toLowerCase()) != -1
            );
        });
    },
    categories() {
      return this.$store.state.categories;
    },
  },
  methods: {
    categorieFormatted(cat) {
      return this.categories.filter((item) => item.value === cat)[0].text
    }
  },
};
</script>
